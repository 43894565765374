<template>
  <div class="title">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'commonTitle',
};
</script>

<style scoped>
.title {
  font-size: 18px;
  color: #000;
  line-height: 35px;
}

.title:before {
  width: 3px;
  background: #048cdb;
  display: inline-block;
  vertical-align: middle;
  height: 14px;
  content: '';
  margin-right: 5px;
}
</style>
